<template>
  <div class="red_top_bg">
    <div
      class="back_left"
      @click.stop="handleBack"
    />
    <div class="big_tit">
      {{ title }}
    </div>
    <p
      class="top_right"
      @click="right_router"
    >
      <slot />
    </p>
  </div>
</template>
<script>
	export default {
		name: "BsHeader",
		props: {
			backurl: {
				type: [String, Number],
				default: -1
			},
			title: {
				type: [String],
				default: ""
			},
			routerurl: {
				type: [String],
				default: ""
			},
		},
		methods: {
			handleBack(e) {
				if (this.backurl != -1) {
					this.$router.push({
						path: this.backurl
					});
					return;
				}
				if (window.history.length > 1) {
					this.$router.go(-1)
				} else {
					this.$router.push({
						path: "index"
					})
				}

			},
			right_router(e) {
				if (this.routerurl != -1) {
					this.$router.push({
						path: this.routerurl
					});
					return;
				}
			}
		},
	}
</script>
<style lang="less" scoped>
	.red_top_bg {
	    position: fixed;
	    top: 0;
	    z-index: 10;
		background-color: #FFFFFF;
	}
</style>
