<template>
  <div class="basic_wrap">
    <bsHeader
      :title="$t('wallet.walletCenter')"
      @backurl="$router.back()"
    />
    <div class="wallet_wrap">
      <div class="wallet_item">
        <div
          v-for="(item,index) in withdrawMethod"
          :key="index"
          class="block_div item"
        >
          <van-cell>
            <template #title>
              <div class="flex_center detail">
                <div class="flex_center">
                  <div>
                    <img
                      :src="item.logo"
                      alt=""
                    >
                  </div>
                  <div>
                    <p>{{ item.name }}</p>
                    <p class="bind_tips">
                      {{ item.tips }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="item.type==4"
                  @click="$router.push('/wallet/bank/'+item.id)"
                >
                  <p
                    v-if="!item.status"
                    class="bind"
                  >
                    {{ $t('wallet.toBind') }}
                  </p>
                </div>
                <div
                  v-if="item.type!=4"
                  @click="$router.push('/wallet/qrcode/'+item.id)"
                >
                  <p
                    v-if="!item.status"
                    class="bind"
                  >
                    {{ $t('wallet.toBind') }}
                  </p>
                </div>
              </div>
            </template>
          </van-cell>
        </div>
        <div class="tips">
          <p class="tips1">
            <span>*</span>{{ $t('wallet.walletTips1') }}
          </p>
          <p class="tips2">
            <span>*</span>{{ $t('wallet.walletTips2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import Vue from 'vue';
	import bsHeader from '../../components/bsHeader.vue'
	import Fetch from '../../utils/fetch'
	import {
		Cell,
		Tag
	} from "vant";

	Vue.use(Cell).use(Tag);
	export default {
		name: "Wallet",
		components: {
			bsHeader
		},
		data() {
			return {
				data: [],
				withdrawMethod: [],
				wallets: []
			};
		},
		created() {
			if (window.plus) {
				plus.navigator.setStatusBarBackground('#FFFFFF');
				plus.navigator.setStatusBarStyle('dark');
			}
			this.$parent.footer('user', false);
		},
		mounted() {
			Fetch('/user/getWithdrawalMethod').then(r => {
				this.withdrawMethod = r.data.withdrawMethod;
				this.wallets = r.data.wallets;
				for (var i = 0; i < this.withdrawMethod.length; i++) {
					this.withdrawMethod[i]['status'] = 0;
					this.withdrawMethod[i]['tips'] = this.$t('wallet.bind') + this.withdrawMethod[i]['name'];
					for (var j = 0; j < this.wallets.length; j++) {
						if (this.wallets[j]['wid'] == this.withdrawMethod[i]['id']) {
							this.withdrawMethod[i]['status'] = 1;
							this.withdrawMethod[i]['tips'] = this.wallets[j][
								'account'
							];
							if (this.withdrawMethod[i]['type'] == 4) {
								this.withdrawMethod[i]['name'] = this.wallets[j][
									'wname'
								];
								if (this.wallets[j]['bank']) {
									this.withdrawMethod[i]['logo'] = this.wallets[j]['bank']['logo'];
								}
							}
						}
					}
				}
			})
		},
		methods: {}
	};
</script>

<style lang="less" scoped>
	.wallet_item {
		margin-top: 54px;

		.item {
			margin-bottom: 10px;

			.detail {
				justify-content: space-between;

				img {
					vertical-align: middle;
					height: 50px;
					width: 50px;
					margin-right: 10px;
				}

				.bind_tips {
					font-size: 12px;
					color: #999;
				}

				.bind {
					color: #999;
				}
			}

		}

		.tips {
			line-height: 2;
			width: 92%;
			margin: 20px 0 0 4%;
			font-size: 14px;

			span {
				margin-right: 5px;
			}

			.tips1 {
				color: #FF0000;
			}
		}
	}
</style>
