<template>
  <div class="basic_wrap">
    <bsHeader
      :title="$t('auth.realname')"
      @backurl="$router.back()"
    />
    <div class="block_div auth_wrap">
      <form
        class="form"
        @submit.prevent="submit"
      >

		<div  v-if="status == 3 || status == 2">
			<div class="tittle_verified">
          {{ $t('auth.realname') }}
        </div>
        <div class="tittle_verified_tips" v-if="status==3">
          {{ $t('auth.realname_tips1') }}
        </div>

		<div class="tittle_verified_tips" v-if="status==2" style="color:red;">
			{{$t('auth.realname_faild')}}
        </div>

        <div class="item">
          <input
            v-model.trim="realname"
            type="text"
            class="inp"
            :placeholder="$t('auth.realname_empty')"
          >
        </div>
        <div class="item">
          <input
            v-model.trim="realname_number"
            type="text"
            class="inp"
            :placeholder="$t('auth.realname_number')"
          >
        </div>
		<p>{{ $t('auth.realname_image') }}</p>
		<div class="qrcode">
            <van-uploader
              v-model="fileList"
              multiple
              :max-count="1"
              class="upload"
              :max-size="2 * 1024 * 1024"
              :after-read="afterRead"
              :before-read="beforeRead"
              @oversize="onOversize"
            />
          </div>
		  <div class="qrcode">
            <van-uploader
              v-model="fileList1"
              multiple
              :max-count="1"
              class="upload"
              :max-size="2 * 1024 * 1024"
              :after-read="afterRead1"
              :before-read="beforeRead"
              @oversize="onOversize"
            />
          </div>
        <button
          type="submit"
          class="basic_btn sbtn"
          :class="email==''||code==''?'no_touch':''"
        >
          {{ $t('auth.submit') }}
        </button>
	</div>
		<div v-else>
			<p v-if="status == 0" style="text-align:center;width:100%;max-width:750px;">{{$t('auth.realname_submited')}}</p>
			<p v-if="status == 1" style="text-align:center;width:100%;max-width:750px;">{{$t('auth.realname_success')}}</p>
		</div>
      </form>
    </div>
  </div>
</template>

<script>
	import Vue from "vue";
	import axios from 'axios'
	import Api from "../../interface/index";
	import {
		Uploader,
		Toast,
		Field,
		CountDown,
		Checkbox
	} from "vant";
	import Fetch from '../../utils/fetch';
	import bsHeader from '../../components/bsHeader.vue';
	Vue.use(CountDown)
		.use(Checkbox).use(Uploader).use(Toast).use(Field);
	export default {
		name: "Setpwd",
		components: {
			bsHeader
		},
		data() {
			return {
				data: {},
				time: 0,
				email: '',
				code: '',
				fileList: [],
				fileList1: [],
				voucher: '',
				voucher1: '',
				realname:"",
				realname_number:"",
				status:3,
			};
		},
		created() {
			if (window.plus) {
				plus.navigator.setStatusBarBackground('#FFFFFF');
				plus.navigator.setStatusBarStyle('dark');
			}
			this.$parent.footer('user', false);
		},
		mounted() {
			 this.start();
		},
		methods: {

			onOversize(file) {
				this.$toast(this.$t('wallet.qrcodeLong'));
			},
			beforeRead(file) {
				if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
					this.$toast(this.$t('wallet.qrcodeError'));
					return false;
				}
				return true;
			},
			afterRead(file) {
				let formData = new FormData();
				formData.append('language', this.$i18n.locale || "en_us");
				formData.append('file', file.file);
				formData.append('token', localStorage.getItem('token'));
				Toast.loading({
					forbidClick: true,
					duration: 20000
				});
				axios.post(Api.commonUrl + "/api/user/imgUpload", formData).then((r) => {
					Toast.clear();
					if (r.data.code === 1) {
						this.voucher = r.data.data;
					} else {
						this.$toast(this.$t(r.data.info));
					}
				});
			},

			afterRead1(file) {
				let formData = new FormData();
				formData.append('language', this.$i18n.locale || "en_us");
				formData.append('file', file.file);
				formData.append('token', localStorage.getItem('token'));
				Toast.loading({
					forbidClick: true,
					duration: 20000
				});
				axios.post(Api.commonUrl + "/api/user/imgUpload", formData).then((r) => {
					Toast.clear();
					if (r.data.code === 1) {
						this.voucher1 = r.data.data;
					} else {
						this.$toast(this.$t(r.data.info));
					}
				});
			},

			start() {
				Fetch('/user/getRealnameStatus').then((r) => {
					if(Object.keys(r.data).length > 0 ){
						this.status = r.data.status
					}
				});
			},

			submit() {
				if (!this.realname) {
					this.$toast(this.$t('auth.realname_empty'));
					return false;
				}

				if (!this.realname_number) {
					this.$toast(this.$t('auth.realname_number'));
					return false;
				}

				if (this.voucher == '') {
					this.$toast(this.$t('auth.realname_image'));
					return false;
				}

				if (this.voucher1 == '') {
					this.$toast(this.$t('auth.realname_image'));
					return false;
				}

				Fetch('/user/authRealname', {
					realname: this.realname,
					realname_number: this.realname_number,
					image:this.voucher,
					image1:this.voucher1
				}).then(() => {
					this.$toast({
						background: '#07c160',
						message: this.$t('auth.realname_submit')
					});
	
					setTimeout(() => {
						this.$router.replace({ path: '/user' });
						}, 1000);

				});
			},
		}
	};
</script>

<style lang="less" scoped>
	.auth_wrap {
		margin-top: 60px;
		padding: 20px 0;
	}
	.qrcode {
			text-align: center;
			margin-top: 20px;
			float:left;
			width:50%;
		}
	.auth_1 {
		text-align: center;
		margin-top: 50%;

		p {
			margin-top: 40px;
			font-size: 20px;
			color: green;
			font-weight: bold;
		}
	}

	.tittle_verified {
		margin-top: 10px;
		margin-bottom: 20px;
		font-size: 26px;
	}

	.tittle_verified_tips {
		line-height: 1.5;
	}

	.form {
		width: 100%;
		// margin-top: 65px;
		padding: 0 20px;

		.item {
			width: 100%;
			height: 45px;
			border-radius: 2px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 40px 0;

			input {
				flex: 1;

				height: 22px;
				font-size: 16px;
				line-height: 22px;
			}
		}
	}

	.sbtn {
		margin-top: 10px;
	}
</style>
