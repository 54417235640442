<template>
  <div class="basic_wrap">
    <div class="red_top_bg">
      <div
        class="back_left"
        @click="$router.back()"
      />
      <div
        class="record"
        @click="$router.push('/withdraw/record')"
      >
        <img src="../img/user/record_b.png">
      </div>
    </div>
    <div class="desc_warp">
      <img src="../img/user/withdraw.png">
      <div class="flex_center userBalance">
        <p>{{ $t('withdraw.fundBalance') }}</p>
        <van-icon
          v-show="frozenAmount!=0"
          name="question-o"
          size="16"
          color="#999"
          style="margin-left: 5px;"
          @click="showMsg()"
        />
      </div>
      <p>{{ common.currency_symbol_basic() }}{{ common.precision_basic(userBalance) }}</p>
    </div>
    <div class="withdraw_wrap">
      <div class="block_div item choose_wallet">
        <div class="flex_center">
          <p>{{ $t('withdraw.account') }}</p>
          <div @click="show=true">
            <span>{{ withdraw_name }}</span>
            <van-icon
              name="arrow"
              color="#999"
            />
          </div>
        </div>
      </div>
      <van-action-sheet
        v-model="show"
        :actions="wallets"
        cancel-text=""
        :description="$t('withdraw.accountPlaceholder')"
        close-on-click-action
        @select="onSelect"
      />
      <div class="block_div item">
        <div class="">
          <p class="withdraw_money_tips" v-if="false">
            {{ $t('withdraw.amount') }}
          </p>
          <div class="flex_center">
            <van-field
              v-model="wallet.money"
              type="number"
              :placeholder="$t('withdraw.amountPlaceholder')"
            />
          </div>
        </div>
      </div>

	  <div class="block_div item" v-if="goods_auth">
        <div class="">
          <p class="withdraw_money_tips" v-if="false">
            {{ $t('withdraw.amount') }}
          </p>
          <div class="flex_center">
            <van-field
              v-model="wallet.google_code"
              type="number"
              :placeholder="$t('auth.google_code')"
            />
          </div>
        </div>
      </div>

        <div class="block_div item elitem" style="position:relative;">
          <van-field
            v-model.trim="code"
            type="text"
            class="inp"
            :placeholder="$t('auth.emailCode')" style="width:80%;position:absolute;top:25px;left:23px;border:0px;"
          />
          <van-count-down
            :time="time"
            class="btn get_captcha"
            @finish="timeCall"
          >
            <template v-slot="timeData">
              <span @click="sendcode" style="float:right;position:absolute;right:30px; top:36px;z-index: 100;">{{
                timeData.seconds > 0
                  ? timeData.seconds
                  : $t('auth.sendEmailCode')
              }}</span>
            </template>
          </van-count-down>
        </div>
    </div>
    <p class="withdraw_time_tips">
      {{ $t('withdraw.withdrawNum') }}{{ withdraw_num }}{{ $t('utils.times'+times) }}<br>
      {{ $t('withdraw.withdrawAmount') }}{{ common.currency_symbol_basic() }}{{ common.precision_basic(withdraw_min) }}<br>
      {{ $t('withdraw.withdrawTips') }}
    </p>
    <div
      class="basic_btn btn"
      :class="wallet.money>0?'':'no_touch'"
      @click="submit()"
    >
      {{ $t('withdraw.withdrawNow') }}
    </div>
  </div>
</template>

<script>
	import Vue from 'vue';
	import bsHeader from '../../components/bsHeader.vue'
	import Fetch from '../../utils/fetch'
	import {
		Icon,
		ActionSheet,
		Field,
		CountDown,
	} from 'vant';
	Vue.use(Icon).use(ActionSheet).use(Field).use(CountDown);
	export default {
		name: "",
		components: {
			bsHeader
		},
		data() {
			return {
				rate: localStorage.getItem('rate'),
				data: [],
				userBalance: "0",
				frozenAmount: '0',
				withdraw_name: this.$t('withdraw.bindAccount'),
				show: false,
				wallets: [],
				wallet: {
					money: '',
					id: 0,
					code:"",
					google_code:""
				},
				withdraw_num: 0,
				withdraw_min: 0,
				times: 0,
				time: 0,
				email: '',
				code: '',
				goods_auth:false,
			};
		},
		created() {
			if (window.plus) {
				plus.navigator.setStatusBarBackground('#FFFFFF');
				plus.navigator.setStatusBarStyle('dark');
			}
			this.$parent.footer('user', false);
		},
		mounted() {
			this.start();
			this.getAuthStatus();
		},
		methods: {
			getAuthStatus() {
				Fetch('/user/getAuthStatus').then((r) => {
					
					this.goods_auth = r.data.auth_authenticator;
				});
			},
			timeCall() {
				this.is_send = false;
				this.time = 0;
			},
			sendcode() {
				if (this.is_send) {
					return false;
				}

				this.is_send = true;

				Fetch("/user/getWithdrawEmailCode", {
					
				}).then((r) => {
					this.time = 60 * 1000;
					this.$toast({
						background: "#07c160",
						message: this.$t('auth.sendsuccess'),
					});
				}).catch(() => {
					this.is_send = false;
				});
			},
			start() {
				Fetch('/user/getWallets').then(r => {
					this.userBalance = r.data.userBalance;
					this.frozenAmount = r.data.frozenAmount;
					this.withdraw_min = r.data.withdrawMin;
					this.withdraw_num = r.data.withdrawNum;
					this.times = r.data.withdrawNum;
					if (this.times > 1) this.times = 2;
					var walletList = [];
					r.data.wallets.forEach(item => {
						walletList.push({
							name: item.wname + "(" + item.account + ")",
							value: item.id,
							type: item.type,
						})
					})

					if (walletList.length == 0) {
						walletList.push({
							name: this.$t('withdraw.bindAccount'),
							value: 0,
							color: "#FF0000",
						})
					}
					this.withdraw_name = walletList[0].name;
					this.wallet.id = walletList[0].value;
					this.wallets = walletList;
				})
			},
			onSelect(item) {
				if (item.value == 0) {
					this.$router.push('/wallet')
				}
				this.withdraw_name = item.name;
				this.wallet.id = item.value;
			},
			changeAmount() {},
			submit() {
				if (this.wallet.id == 0) {
					this.$toast(this.$t('withdraw.accountEmpty'));
					return false;
				}
				if (this.wallet.money == "") {
					this.$toast(this.$t('withdraw.amountEmpty'));
					return false;
				}
				if (this.wallet.money - this.userBalance > 0) {
					this.$toast(this.$t('withdraw.amountError'));
					return false;
				}
				if (this.wallet.money - this.withdraw_min < 0) {
					this.$toast(this.$t('withdraw.withdrawAmount')+this.common.currency_symbol_basic()+this.common.precision_basic(this.withdraw_min));
					return false;
				}

				if(this.wallet.google_code == "" && this.goods_auth){
					this.$toast(this.$t('auth.google_code'));
					return false;
				}

				if(this.code.length == 0){
					this.$toast(this.$t('auth.codeEmpty'));
					return false;
				}

				this.wallet.code = this.code;

				Fetch('/user/withdraw', {
					...this.wallet,
				}).then(r => {
					this.$router.replace('/withdraw/record');
				})
			},
			showMsg() {
				this.$dialog.alert({
					closeOnClickOverlay: true,
					showConfirmButton: false,
					message: "<p style='text-align: left'>" +
						this.$t('withdraw.frozenAmount') +
						this.common.currency_symbol_basic()+
						this.common.precision_basic(this.frozenAmount) +
						" " +
						"<br>" +
						this.$t('withdraw.needInvest') +
						"</p>",
				}).catch(() => {
					// on close
				});
			}
		}
	};
</script>


<style lang="less" scoped>

.van-cell::after{
	position: static !important;
}
	.red_top_bg {
		position: fixed;
		top: 0;
		z-index: 10;
		background-color: #FFFFFF;

		.record {
			position: absolute;
			top: 11px;
			right: 15px;

			img {
				width: 22px;
				height: 22px;
			}
		}
	}

	.desc_warp {
		margin-top: 60px;
		text-align: center;
		.userBalance{
			justify-content: center;
		}

		img {
			width: 100px;
			height: 100px;
		}

		p:nth-child(2) {
			font-size: 14px;
			color: #999;
		}

		p:nth-child(3) {
			font-size: 20px;
			font-weight: bold;
			margin: 10px 0;
		}
	}

	.withdraw_wrap {
		.item {
			margin-bottom: 10px;
			padding: 20px;

			.flex_center {
				justify-content: space-between;
			}

			.withdraw_money_tips {
				margin-bottom: 10px;
			}

			.money_mark {
				font-size: 18px;
				font-weight: bold;
				margin-left: 6px;
			}

			div:nth-child(1) {
				font-size: 14px;
				// font-weight: bold;
				color: #999;
			}

			// div:nth-child(2) {
			// 	text-align: right;
			// 	font-size: 14px;
			// 	font-weight: bold;
			// 	color: #999;
			// }
		}

	}

	.withdraw_time_tips {
		padding: 10px 16px 14px 16px;
		line-height: 2;
		font-size: 12px;
		color: #f12211;
	}

	.btn {
		width: 80%;
		margin: 50px 0 0 10%;
	}

	/deep/ .van-cell {
		padding: 10px 16px 10px 10px;
	}

	/deep/ .van-action-sheet__item {
		text-align: left;
	}

	/deep/ .van-action-sheet__item {
		padding: 10px 16px;
		font-size: 14px;
	}

	/deep/ .van-dialog__message {
		text-align: left;
	}
	/deep/.van-action-sheet{
		padding-bottom: 10px;
	}
</style>