import { render, staticRenderFns } from "./article_detail.vue?vue&type=template&id=61703856&scoped=true"
import script from "./article_detail.vue?vue&type=script&lang=js"
export * from "./article_detail.vue?vue&type=script&lang=js"
import style0 from "./article_detail.vue?vue&type=style&index=0&id=61703856&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61703856",
  null
  
)

export default component.exports